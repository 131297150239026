<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>
            Applications for payment
            <span class="float-right">Total: {{ total | priceInPounds | currency }}</span>
        </h1>
        <v-form @submit.prevent="getInvoices">
          <v-row class="pt-8">
            <v-col>
              <label>Customer</label>
              <v-text-field v-model="customer"></v-text-field>
            </v-col>
            <v-col>
              <label>Sage Ref</label>
              <v-text-field v-model="sageRef"></v-text-field>
            </v-col>
            <v-col>
              <label>Application No</label>
              <v-text-field v-model="applicationNumber"></v-text-field>
            </v-col>
            <v-col>
            <label>Sales initials</label>
              <v-text-field v-model="salesPerson"></v-text-field>
            </v-col>
            <v-col>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="pl-8">Date</label>
                  <v-text-field
                    v-model="date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  range>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col>
              <v-btn class="mb-2" x-small block type="sumbit">Search</v-btn>
              <v-btn class="mb-2" x-small block @click="clearSearch">Clear</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="text-left">No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Address</th>
                <th class="text-left">Date</th>
                <th class="text-left">Value</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoices"
                :key="invoice.id"
              >
                <td>{{ invoice.statusLetter }}</td>
                <td>{{ invoice.invoiceNumber }}</td>
                <td>{{ invoice.customer }}</td>
                <td>{{ invoice.siteLocation }}</td>
                <td>{{ invoice.date | tinyDate }}</td>
                <td>{{ invoice.value | priceInPounds | currency }}</td>
                <td class="pr-0">
                  <router-link
                    v-if="userPermissions.includes(4)"
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/view-draft/${invoice.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                  <router-link
                    v-else
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/print-view/${invoice.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  data() {
    return {
      applicationNumber: '',
      customer: '',
      date: null,
      invoices: [],
      length: 0,
      page: 1,
      paymentStatus: 0,
      sageRef: '',
      salesPerson: '',
      totalVisible: 9,
      total: 0,
    };
  },
  watch: {
    page() {
      this.getInvoices();
    },
  },
  methods: {
    clearSearch() {
      this.invoices = [];
      this.applicationNumber = '';
      this.customer = '';
      this.date = [];
      this.salesPerson = '';
      this.sageRef = '';
      this.paymentStatus = 0;
      this.getInvoices();
    },
    setApplicationSearchParams() {
      const params = {};
      params.applicationNumber = this.applicationNumber;
      params.customer = this.customer;
      params.date = this.date;
      params.salesPerson = this.salesPerson;
      params.sageRef = this.sageRef;
      params.paymentStatus = this.paymentStatus;
      this.$store.commit('setApplicationSearchParams', params);
    },
    getSearchParams() {
      this.applicationNumber = this.$store.state.applicationSearchParams.applicationNumber;
      this.customer = this.$store.state.applicationSearchParams.customer;
      this.date = this.$store.state.applicationSearchParams.date;
      this.salesPerson = this.$store.state.applicationSearchParams.salesPerson;
      this.sageRef = this.$store.state.applicationSearchParams.sageRef;
      this.paymentStatus = this.$store.state.applicationSearchParams.paymentStatus;
      this.getInvoices();
    },
    getInvoices() {
      this.setApplicationSearchParams();
      const postData = {
        applicationNumber: this.applicationNumber,
        customer: this.customer,
        date: this.date,
        salesPerson: this.salesPerson,
        sageRef: this.sageRef,
        paymentStatus: this.paymentStatus,
      };
      axios.post(`/invoices/getApplication/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.invoices = response.data.invoices;
          this.length = response.data.length;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getSearchParams();
  },
};
</script>
